import { API } from '@/utilities/http-common'

const emailExists = (email, token = null) => {
  const options = {
  }
  if (token !== null) {
    options.headers = { 'X-CSRF-Token': token }
  }
  return API.get('/members/check-email/' + email, options)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.email_exists
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const usernameExists = (username, token = null) => {
  const options = {
  }
  if (token !== null) {
    options.headers = { 'X-CSRF-Token': token }
  }
  return API.get('/members/check-username/' + username, options)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.username_exists
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const resetPassword = (value, token = '') => {
  const options = {
  }
  if (token !== null) {
    options.headers = { 'X-CSRF-Token': token }
  }
  return API.post('/members/recover-password', value, options)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

const createMember = (member, token = '') => {
  const options = {
  }
  if (token !== null) {
    options.headers = { 'X-CSRF-Token': token }
  }
  return API.put('/members/add', member, options)
    .then(response => {
      if (response.status === 200) {
        const data = response.data
        return data.member
      } else {
        const error = new Error(response.statusText)
        error.code = response.status
        throw error
      }
    })
}

export { emailExists, usernameExists, resetPassword, createMember }
