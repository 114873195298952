<template>
  <div>
    <div v-if="message !== ''"
         :class="`alert-${messageType}`"
         class="alert">
      {{ message }}
    </div>
    <div class="card border-secondary">
      <div class="card-header text-center">
        <h3 class="align-middle"><img src="/img/logo.png" alt="logo" class="pr-2">Become a Member</h3>
      </div>
      <div class="card-body">
        <p class="card-title">Your new membership account allows you to have one login for multiple students. It is
          shared with Community Schools <span v-html="toList(programNames)"/> online registrations. The first step is to
          create a
          new membership account, you will then be guided to add students to the account.</p>
        <p class="card-subtitle">If you do not remember if you have an account please <a
            href="/members/request-reset-password"
            :class="['btn', 'btn-outline-primary', 'float-right','btn-light']"
            tabindex="6">Request Password Reset</a> where you can reset your password with a username or email, or call
          Community Schools, <a href="tel:(520)209-7551" class="btn btn-outline-info">
            <cs-icon icon="phone"/>
            (520)209-7551</a>
        </p>
      </div>
      <div class="card-body">
        <form @submit="validateForm"
              :action="action"
              method="POST"
              accept-charset="utf-8"
              class="form-signin"
        >
          <fieldset>
            <div class="row">

              <div class="col form-group text required">
                <label for="first_name" class="control-label">First Name</label>
                <input id="first_name"
                       name="first_name"
                       v-model="first_name"
                       placeholder="first name"
                       required
                       class="form-control"
                       :class="first_name === ''?'is-invalid':''">

              </div>
              <div class="col form-group text required">
                <label for="last_name" class="control-label">Last Name</label>
                <input id="last_name"
                       v-model="last_name"
                       name="last_name"
                       placeholder="first name"
                       required
                       class="form-control"
                       :class="last_name === ''?'is-invalid':''">
              </div>
            </div>
            <transition>
              <div v-if="missingName" class="row">
                <div class="col alert alert-danger">
                  First and last name are required for members.
                </div>
              </div>
            </transition>
            <div class="row">
              <div class="col text-center"><span
                  class="text-muted">This should be <strong>YOUR NAME</strong> not your student(s)</span>
              </div>
            </div>
            <div class="row">
              <div class="col form-group text required">
                <label for="username" class="control-label">User name</label>
                <input id="username"
                       v-model="username"
                       name="username"
                       @blur="usernameUnique"
                       placeholder="your user name"
                       required
                       class="form-control"
                       :class="username === '' && !emailExists?'is-invalid':''">
                <div v-if="attemptRegister && missingUsername" class="invalid-feedback">You need to
                  enter a
                  <strong>user name</strong>
                  <transition name="fade">

                  </transition>
                </div>
              </div>
              <div class="col">
                <span v-bind:class="{ 'text-danger' : userExists,
                               'text-muted': !userExists }">
                  The user name must be unique, if we find the user name you can send a password reset email
                </span>
                <cs-loading v-if="$wait.is('new-member.username')" sm>Checking if username already exists</cs-loading>
              </div>
            </div>
            <transition mode="out-in">
              <div v-if="userExists" class="row" key="usernameExists">
                <div class="col alert alert-danger">
                  <strong>{{ username }}</strong> exists if you would like to reset the
                  password click
                  <button v-on:click.prevent="resetPassword"
                          class="btn btn-primary btn-sm">Recover Password
                  </button>
                </div>
              </div>
              <div v-else-if="!userExists && userChecked" key="usernameOk" class="row">
                <div class="col alert alert-success">
                  <strong>{{ username }}</strong> is unique
                </div>
              </div>
            </transition>
            <div class="row">
              <div class="col form-group required text">
                <label for="email" class="control-label required">Email</label>
                <input id="email"
                       v-model="email"
                       name="email"
                       @blur="emailUnique"
                       placeholder="email"
                       type="email"
                       required
                       class="form-control"
                       :class="email === ''?'is-invalid':''">

              </div>
              <div class="col form-group required text">
                <label for="confirmEmail" class="control-label">Confirm Email</label>
                <input id="confirmEmail"
                       v-model="confirmEmail"
                       placeholder="confirm email"
                       name="email_confirm"
                       type="email"
                       required
                       class="form-control"
                       :class="confirmEmail === '' || !emailsMatch?'is-invalid':''">

                <div v-if="!emailsMatch || emailExists" class="invalid-feedback">Email addresses do not
                  match.
                </div>
              </div>
            </div>
            <transition name="fade">
              <cs-loading v-if="$wait.is('new-member.email')" sm>Checking if email already exists</cs-loading>
            </transition>
            <transition>
              <div v-if="emailExists" class="row">
                <div class="col alert alert-danger">
                  {{ email }} exists if you would like to reset the password click
                  <button v-on:click.prevent="resetPassword"
                          class="btn btn-primary btn sm">Recover Password
                  </button>
                </div>
              </div>
            </transition>
            <div class="row">
              <div class="col  form-group required text">
                <label for="password" class="control-label required">Password</label>
                <input id="password"
                       v-model="password"
                       type="password"
                       name="password"
                       placeholder="password"
                       required
                       class="form-control"
                       :class="password === '' || !passwordsMatch?'is-invalid':''">

              </div>
              <div class="col  form-group required text">
                <label for="confirmPassword" class="control-label required">Confirm Password</label>
                <input id="confirmPassword"
                       v-model="confirmPassword"
                       name="password_confirm"
                       type="password"
                       placeholder="confirm password"
                       required
                       class="form-control"
                       :class="confirmPassword === '' || !passwordsMatch?'is-invalid':''">

                <div v-if="!passwordsMatch" class="invalid-feedback">passwords do not match.</div>
              </div>

            </div>
          </fieldset>
          <div style="display: none;">
            <input v-for="data in inputHiddenData"
                   :name="data.name"
                   :value="data.value"
                   :key="data.name" type="hidden">
          </div>
          <cs-button :type="valid?'primary':'danger'"
                     class="btn-block"
                     buttonType="submit"
                     :disabled="!valid"
                     :loading="$wait.is('new-member.*')"
                     icon-left="add-member"
                     tabindex="4">Create new Member
          </cs-button>
        </form>
      </div>
      <div class="card-body">
        <ul>
          <li>You will have one login for all your students</li>
          <li>If your student is NEW to CFSD, you will add their information using our secure forms</li>
          <li>If you do not remember if you have an account please use
            the <a href="/members/request-reset-password"
                   :class="['btn', 'btn-outline-primary','btn-light']"
                   tabindex="6">Request Password Reset</a> where you can reset your password with a
            username or email, or call Community Schools, <a href="tel:(520)209-7551" class="btn btn-outline-info">
              <cs-icon icon="phone"/>
              (520)209-7551</a>
          </li>
          <li>Once you create your account and add your students, you can then access the registration
            sites and enroll in any of the enrichment programs or register for C.A.R.E.
          </li>
          <li>Each Program may have additional information specific to the needs of that program that you
            may need to complete before starting signing up your students in those programs.
          </li>
        </ul>
        <p>If you have any questions please call the Community Schools main office at
          <a href="tel:(520)209-7551" class="btn btn-outline-info">
            <cs-icon icon="phone"/>
            (520)209-7551</a> or by
          program
        </p>
        <ul>
          <li v-for="program in activePrograms"
              class="align-content-center"
              :key="program.slug">
            <strong v-html="program.name"></strong> <a :href="`tel:${program.phone}`" class="btn btn-outline-info px-2">
            <cs-icon icon="phone"/>
            {{ phone(program.phone) }}</a>
            <a :href="`mailto:${program.admin_email}`" class="btn btn-outline-info">
              <cs-icon icon="email"/>
              {{ program.admin_email }}</a>
          </li>
        </ul>
      </div>

      <div class="card-footer">
        <div class="row justify-content-around">
          <div class="col-4">
            <cs-button @handle-click="$emit('login')"
                       type="primary"
                       :is-outline="true"
                       icon-left="login"
            >Login
            </cs-button>
          </div>
          <div class="col-4">
            <a href="/members/request-reset-password"
               :class="['btn', 'btn-outline-primary', 'float-right','btn-light']"
               tabindex="6">Request Password Reset</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { toList, phone } from '@/utilities/stringFormatters'
import { usernameExists, emailExists, resetPassword } from '@/api/memberAPI'

export default {
  name: 'NewMember',
  props: {
    programs: {
      type: Array,
      default: () => []
    },
    token: {
      type: String,
      required: true
    },
    inputHiddenData: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loggedIn: window.loggedIn,
      username: '',
      password: '',
      first_name: '',
      last_name: '',
      attemptRegister: false,
      registerPassword: '',
      confirmPassword: '',
      email: '',
      emailExists: false,
      emailChecked: false,
      userExists: false,
      userChecked: false,
      confirmEmail: '',
      register: false,
      message: '',
      messageType: '',
      nameChecked: false,
      attemptSubmit: false
    }
  },
  watch: {
    email () {
      if (this.emailExists) {
        this.emailExists = false
      }
    },
    username () {
      if (this.userExists) {
        this.userExists = false
      }
    }
  },
  computed: {
    missingName () {
      return this.first_name.trim() === '' || this.last_name.trim() === ''
    },
    activePrograms () {
      return this.programs.filter((program) => {
        return program.active
      })
    },
    programNames () {
      return this.activePrograms.map((program) => {
        return `<a :href="/${program.slug}" class="text-${program.slug}">${program.title}</a>`
      })
    },
    emailsMatch () {
      return this.email.trim() !== '' && this.email.trim() === this.confirmEmail.trim()
    },
    passwordsMatch () {
      return this.password.trim() !== '' && this.password === this.confirmPassword
    },
    valid () {
      return !this.emailExists && !this.userExists && !this.missingName &&
          this.emailsMatch && this.passwordsMatch
    }
  },
  methods: {
    toList,
    phone,
    resetPassword () {
      this.$wait.start('new-member.reset-password')
      const value = {}
      if (this.username !== '' && this.userExists) {
        value.username = this.username
      }
      if (this.email !== '' && this.emailExists) {
        value.email = this.email
      }
      resetPassword(value, this.token)
        .then(result => {
          console.log('resetPassword', result)
          this.$wait.end('new-member.reset-password')
          if (result.isError) {
            this.messageType = 'danger'
            this.message = result.errorMessages
          } else {
            this.messageType = 'success'
            this.message = result.resultMessages
            this.$emit('login', { username: this.username })
          }
        })
        .catch(e => {
          console.error('resetPassword', e)
          this.$wait.end('new-member.reset-password')
        })
    },
    emailUnique () {
      if (this.email.trim() !== '') {
        this.$wait.start('new-member.email')
        emailExists(this.email)
          .then(result => {
            console.log('emailUnique', result)
            this.emailExists = result
            this.$wait.end('new-member.email')
          })
          .catch(e => {
            console.error('emailUnique', e)
            this.$wait.end('new-member.email')
          })
      }
    },
    usernameUnique () {
      if (this.username.trim() !== '') {
        this.$wait.start('new-member.username')
        usernameExists(this.username)
          .then(result => {
            console.log('usernameUnique', result)
            this.userExists = result
            this.$wait.end('new-member.username')
          })
          .catch(e => {
            console.error('usernameUnique', e)
            this.$wait.end('new-member.username')
          })
      }
    },
    validateForm: function (event) {
      this.attemptSubmit = true
      if (!this.valid) {
        console.log('stop event')
        event.preventDefault()
      } else {
        console.log('continue event')
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>
